export const JBQBreadcrumb = () => import('./jbq-breadcrumb');
export const JBQHeader = () => import('./jbq-header');
export const JBQFooter = () => import('./jbq-footer');
export const JBQFooterPrimaryInstitutionalMenu = () => import('./jbq-footer-primary-institutional-menu');
export const JBQLinkText = () => import('./jbq-link-text');
export const JBQBackToTopLink = () => import('./jbq-back-to-top-link');
export const JBQNotFound = () => import('./jbq-not-found');
export const JBQBannerCarousel = () => import('./jbq-banner-carousel');
export const JBQBanner = () => import('./jbq-banner');
export const JBQSpotlightCardCarousel = () => import('./jbq-spotlight-card-carousel');
export const JBQSpotlightCard = () => import('./jbq-spotlight-card');
export const JBQBannerBoxWithTextContent = () => import('./jbq-banner-box-with-text-content');
export const JBQMessage = () => import('./jbq-message');
export const JBQMessageCarousel = () => import('./jbq-message-carousel');
export const JBQMosaic = () => import('./jbq-mosaic');
export const JBQCategoryThumb = () => import('./jbq-category-thumb');
